import { createRouter, createWebHistory } from 'vue-router'

const routes = [
	{
		path: '/',
		name: 'home',
		component: () => import('@/views/Index.vue'),
		meta: {
			titlePage: 'Title page'
		}
	},
	{
		path: '/manufacturers',
		name: 'manufacturers',
		component: () => import('@/views/Manufacturers.vue'),
		meta: {
			titlePage: 'Title page'
		}
	}
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})

export default router