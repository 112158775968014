import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'

import '@/assets/css/bootstrap-icons.min.css'
import '@/assets/css/glightbox.css'
import '@/assets/css/style.css'
import '@/assets/css/other.css'

import bootstrap from 'bootstrap/dist/js/bootstrap.bundle.js'

createApp(App)
    .use(router)
    .use(bootstrap)
    .mount('#app')